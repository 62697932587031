import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

const ContactPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Contact — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={"Contact page of " + site.siteMetadata.description}
        />
      </Helmet>
      <div className="one-grids -contact">
        <div
          className="post-thumbnail"
          style={{
            marginBottom: 0,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <h1 className="post-title">Get in Touch</h1>
          <p
            style={{
              color: "#fff",
              fontWeight: 200,
              fontSize: 20,
              width: "80%",
              textAlign: "center",
            }}
          >
            We appreciate constructive criticism, honest feedback and useful
            link suggestions.
            <br />
            Drop us a line or two and we will give our best to reply as soon as
            possible.
          </p>
          <p style={{ fontSize: 18 }}>
            Talk to Us You can send us an email at{" "}
            <a
              style={{ color: "#fff", fontWeight: 200 }}
              href="mailto:infocode4ever@gmail.com"
            >
              infocode4ever@gmail.com
            </a>
          </p>
          <p>
            <a
              style={{ color: "#fff", fontWeight: 200 }}
              href="https://twitter.com/Code4Ever2"
            >
              @Code4Ever2
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default ContactPage;
export const pageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
